<template>
  <div
    :class="rootClasses"
    class="MultiImage"
    @click="onClick">
    <div class="TopPadding"/>
    <div class="Inner">
      <img
        v-show="url"
        :src="url"
        ref="Image"
        @load="onImageLoaded"
        @error="onImageError" />
      <div
        v-if="isLoading"
        class="ProgressWrap">
        <Circular :size="loadingSize" />
      </div>
      <div
        v-if="failed"
        class="FailedWrap">
        <WarningIcon />
      </div>
      <slot/>
    </div>

    <ImageInspectModal
      v-if="inspectImage.url"
      :url="inspectImage.url"
      :enableDelete="enableDelete"
      :onCustomConfirmDelete="onCustomConfirmDelete"
      :enableReupload="enableReupload"
      @close="onIspectImageClose"
      @delete=onImageDelete
      @reupload=onImageReupload />
  </div>
</template>

<script>
import Circular from '@/components/Progress/Circular.vue'
import WarningIcon from '@/components/Icons/WarningIcon.vue'
import ImageInspectModal from '@/components/ImageInspectModal.vue'
import EventBus from '@/EventBus'
import { mapGetters } from 'vuex'
import { HOST } from '@/globals/javascript/_util/host'

export default {
  name: 'MultiImage',
  props: {
    image: {
      type: Object,
      required: true,
    },
    transformation: {
      type: String,
      required: true,
    },
    loadingSize: {
      type: String,
      required: false,
      default: 'small',
    },
    preview: {
      type: Boolean,
      required: false,
      default: false,
    },
    enableDelete: {
      type: Boolean,
      required: false,
      default: false,
    },
    onCustomConfirmDelete: {
      type: Function,
      required: false,
    },
    enableReupload: {
      type: Boolean,
      required: false,
      default: false,
    },
    keepSquare: {
      type: Boolean,
      required: false,
      default: true,
    },
  },
  data() {
    return {
      url: '',
      dataURL: '',
      isLoading: false,
      failed: false,
      inspectImage: {
        url: '',
      },
    }
  },
  computed: {
    ...mapGetters([
      'imageUploadList',
    ]),
    rootClasses() {
      return {
        KeepSquare: this.keepSquare,
        FillSpace: !this.keepSquare,
        Preview: this.preview,
      }
    },
    imagePath() {
      return this.image.base.path
    },
    rawImageURL() {
      return `${ HOST.cloudinary }/${ this.imagePath }`
    },
  },
  watch: {
    size(to, from) {
      if (to !== from) {
        this.loadImage()
      }
    },
    image(to, from) {
      const toPath = to?.base?.path
      const fromPath = from?.base?.path
      if (toPath !== fromPath) {
        this.loadImage()
      }
    },
  },
  methods: {
    onImageLoaded() {
      this.isLoading = false
      this.$emit('on-load')
    },
    onImageError() {
      this.isLoading = false
      this.$emit('on-error')
    },
    onTemporaryURLLoaded({ basePath, dataURL }) {
      if (this.image.base.path !== basePath) {
        return
      }

      this.dataURL = dataURL
      this.loadImage()
    },
    onImageUploaded({ path }) {
      if (this.image.base.path !== path) {
        return
      }

      if (!this.dataURL) {
        this.loadImage()
      }
    },
    onImageUploadFailed({ path }) {
      if (this.image.base.path !== path) {
        return
      }

      this.dataURL = ''
      this.loadImage()
    },
    loadImage() {
      // Reset
      this.url = ''
      this.isLoading = true
      this.failed = false

      // Check for temporary data URL
      if (this.dataURL) {
        this.url = this.dataURL
        return
      }

      // Check for being uploaded
      const uploadItem = this.imageUploadList.find((x) => x.path === this.image.base.path)
      if (uploadItem && ['initial', 'uploading'].includes(uploadItem.status)) {
        return
      }
      if (uploadItem && uploadItem.status === 'failed') {
        this.isLoading = false
        this.failed = true
        return
      }

      // Check to get image from emulator
      const emulatorServices = process.env.VUE_APP_EMULATORS || ''
      if (emulatorServices === 'all' || emulatorServices.includes('storage')) {
        this.mixGetDownloadURL({ path: this.imagePath })
          .then((url) => {
            this.url = url
          })
          .catch(() => {
            this.isLoading = false
            if (!this.dataURL) {
              this.failed = true
            }
          })
        return
      }

      let url = `${ this.rawImageURL }?tx=t_${ this.transformation }`

      // Default cloudinary tranformation
      if (this.transformation === 'default') {
        url = `${ this.rawImageURL }?tx=t_auto_format_auto_quality`
      }

      // Requisition account logo
      if (this.transformation === 'requisition_account_logo') {
        url = `${ this.rawImageURL }?tx=c_fit,h_60,w_160`
      }

      this.url = url
    },
    onClick() {
      if (!this.preview) {
        return
      }

      if (this.enableReupload && this.failed) {
        const answer = window.confirm(this.mixWB('ARE_YOU_SURE_YOU_WANT_TO_UPLOAD_A_NEW_IMAGE'))
        if (answer) {
          this.onImageReupload()
        }
        return
      }

      if (this.enableDelete) {
        const uploadItem = this.imageUploadList.find((x) => x.path === this.image.base.path)
        if (uploadItem && ['initial', 'uploading'].includes(uploadItem.status)) {
          const answer = window.confirm(this.mixWB('THE_IMAGE_DOES_NOT_APPEAR_TO_HAVE_BEEN_UPLOADED_YET_DO_YOU_WANT_TO_DELETE_IT'))
          if (answer) {
            this.$emit('delete')
          }
          return
        }

        // Check for failed
        if (this.failed) {
          const answer = window.confirm(this.mixWB('THE_IMAGE_DOES_NOT_APPEAR_TO_EXIST_DO_YOU_WANT_TO_DELETE_IT'))
          if (answer) {
            this.$emit('delete')
          }
          return
        }

        // Check for URL
        if (!this.url) {
          const answer = window.confirm(this.mixWB('DOWNLOADING_IMAGE_DO_YOU_WANT_TO_DELETE_IT'))
          if (answer) {
            this.$emit('delete')
          }
          return
        }
      }

      // Show preview
      // - Check to use dataURL
      if (this.dataURL) {
        this.inspectImage.url = this.dataURL
        return
      }

      // - Check to get image from emulator
      const emulatorServices = process.env.VUE_APP_EMULATORS || ''
      if (emulatorServices === 'all' || emulatorServices.includes('storage')) {
        this.inspectImage.url = this.url
        return
      }

      // - Use cloudinary
      this.inspectImage.url = `${ this.rawImageURL }?tx=t_resize_min_800`
    },
    onIspectImageClose() {
      requestAnimationFrame(() => {
        this.inspectImage.url = ''
      })
    },
    onImageDelete() {
      this.$emit('delete')
    },
    onImageReupload() {
      this.onIspectImageClose()
      this.$emit('reupload')
    },
  },
  components: {
    Circular,
    WarningIcon,
    ImageInspectModal,
  },
  mounted() {
    this.loadImage()
    EventBus.$on('image-data-url-created', this.onTemporaryURLLoaded)
    EventBus.$on('image-uploaded', this.onImageUploaded)
    EventBus.$on('image-upload-failed', this.onImageUploadFailed)
  },
  destroyed() {
    EventBus.$off('image-data-url-created', this.onTemporaryURLLoaded)
    EventBus.$off('image-uploaded', this.onImageUploaded)
    EventBus.$off('image-upload-failed', this.onImageUploadFailed)
  },
}
</script>

<style lang="stylus" scoped>
  .MultiImage
    position relative
    width 100%
    &.KeepSquare
      overflow hidden
    &.FillSpace
      height 100%
    &.Preview
      cursor pointer

  .TopPadding
    width 100%
    padding-top 100%
    .FillSpace &
      display none

  .Inner
    box(100%)
    .KeepSquare &
      position absolute
      top 0
      left 0
      img
        box(100%)
        object-fit contain
        background-color $color_grey_light
    .FillSpace &
      img
        max-width 100%
        max-height 100%

  .ProgressWrap
    box(100%)
    position absolute
    top 0
    left 0

  .FailedWrap
    box(100%)
    padding 25%
    position absolute
    top 0
    left 0
    border 1px solid $color_grey_lighter
</style>
