<template>
  <div class="RequisitionSampleDetailModal">
    <span class="Title">{{ title }}</span>
    <span class="SubTitle">{{ sample.getKindOfSampleText() }}</span>

    <div class="Section">
      <span class="SectionTitle">{{ mixWB('UNITS') }}</span>
      <span class="SectionContent">{{ this.material.getUnitsList() }}</span>
    </div>

    <div class="Section Images">
      <div class="SectionTitle">{{ mixWB('IMAGES') }}</div>
      <div class="SectionContent">
        <MultiImage
          :image="sample.images.close"
          transformation="square_400" />
        <MultiImage
          :image="sample.images.away"
          transformation="square_400" />
      </div>
    </div>
  </div>
</template>

<script>
import MultiImage from '../Images/MultiImage.vue'

export default {
  name: 'RequisitionSampleDetailModal',
  props: {
    sample: {
      type: Object,
      required: true,
    },
    material: {
      type: Object,
      required: true,
    },
  },
  computed: {
    title() {
      return `P${
        this.sample.sampleNumber
      } - ${
        this.material.getTitle({ category: true, details: true })
      }`
    },
  },
  components: { MultiImage },
}
</script>

<style lang="stylus" scoped>
  .RequisitionSampleDetailModal
    .SubTitle + .Section, .Section + .Section
      margin-top 20px

  .Title
    modalTitle()
    margin-bottom 0

  .SubTitle
    text-align center

  .Section
    .SectionTitle
      padding-bottom 3px
      text-transform uppercase
      font-size 0.75rem
      border-bottom 1px solid $color_grey_lighter
    .SectionContent
      padding 5px 10px

  .Images
    .SectionContent
      display flex
      flex-wrap wrap
      padding 5px

      > *
        max-width calc(100% - 10px)
        width 25%
        flex-grow 1
        min-width 250px
        margin 5px

</style>
