<template>
  <div class="RequisitionCreation">
    <!-- Laboratory options -->
    <RequisitionOptions @changes-status-update="onOptionStatusChange" />

    <div class="RequisitionSetup">
      <RequisitionTableSamples />
    </div>
  </div>
</template>

<script>
import { mapActions } from 'vuex'
import RequisitionOptions from '@/components/RequisitionFlow/RequisitionOptions.vue'
import RequisitionTableSamples from '@/components/RequisitionFlow/RequisitionTableSamples.vue'

export default {
  name: 'RequisitionCreation',
  methods: {
    ...mapActions([
      'setHasUnsaveChanges',
    ]),
    onOptionStatusChange(status) {
      this.setHasUnsaveChanges(status)
    },
  },
  components: {
    RequisitionOptions,
    RequisitionTableSamples,
  },
}
</script>

<style lang="stylus" scoped>
  .RequisitionCreation
    > * + *
      margin-top 30px

  .RequisitionSetup
    position relative
    min-height 100px
    page-wrap-outer-1()
    background-color #fff
    box-shadow $box_shadow_1
</style>
