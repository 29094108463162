<template>
  <div class="RequisitionSampleMenu">
    <DotMenuItem
      :text="mixWB('VIEW_DETAILS')"
      itemID="details"
      @dot-item-click="onDotItemClick" />

    <DotMenuItem
      :text="mixWB('ADD_ANALYSES')"
      itemID="add-samples"
      :isDisabled="isAddManualSamplesDisabled"
      @dot-item-click="onDotItemClick" />
  </div>
</template>

<script>
import DotMenuItem from '../DotMenu/DotMenuItem.vue'

export default {
  name: 'RequisitionSampleMenu',
  props: {
    isAddManualSamplesDisabled: {
      type: Boolean,
      required: true,
    },
  },
  methods: {
    onDotItemClick(itemID) {
      this.$emit('pass-to-parent', { name: 'option-click', value: itemID })
      this.$emit('close-menu')
    },
  },
  components: { DotMenuItem },
}
</script>
