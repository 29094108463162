<template>
  <div class="RequisitionTableRowHead">
    <div class="RowGrid">
      <div/>
      <div/>
      <div/>

      <div
        v-for="analysis in analysesData"
        :key="analysis.id"
        :data-analysis="analysis.id">
        <span>{{ analysis.title.selected }}</span>
        <span>{{ analysis.code }}</span>
      </div>

    </div>

    <div/>
  </div>
</template>

<script>
export default {
  name: 'RequisitionTableRowHead',
  props: {
    analysesData: {
      type: Array,
      required: true,
    },
  },
}
</script>

