<template>
  <div class="RequisitionTableRowSample">
    <div class="RowGrid">
      <div>
        <CheckIcon
          v-if="sample.isCompleted"
          class="IsCompletedSVG"/>

        <div
          v-else
          class="RequisitionCheckbox"
          :class="{
            PartialSelection: analyses.length && !allAnalysisSelected
          }">
          <div class="RequisitionCheckboxWrap">
            <input
              type="checkbox"
              id="sample"
              v-model="sampleChecked"
              :true-value="true"
              :false-value="false"
              @click.exact="toggleAllAnalyses(!sampleChecked)"
              @click.ctrl.exact="onModifiedSelection()"
              @click.shift.exact="onModifiedSelection()"
              @click.ctrl.shift.exact="onModifiedSelection()" />
            <CloseIcon />
          </div>
        </div>
      </div>

      <div>{{ sample.displayID }}</div>
      <div class="Title">
        <span>{{ sample.title.selected }}</span>
        <span>{{ sample.rawSample.getKindOfSampleText() }}</span>
      </div>

      <div
        v-for="analysis in sample.analyses"
        :key="analysis.id"
        :data-analysis="analysis.sampleTypeID">
        <CheckIcon
          v-if="analysis.isCompleted"
          class="IsCompletedSVG"/>

        <div
          v-else-if="analysis.missingResult"
          class="RequisitionCheckbox">
          <div class="RequisitionCheckboxWrap">
            <input
              type="checkbox"
              id="analyses"
              v-model="analyses"
              @click="onCheckboxClick"
              :value="analysis.sampleTypeID" />
            <CloseIcon />
          </div>
        </div>
      </div>

    </div>

    <div class="MenuButtonWrap">
      <DotMenu
        :use-component="RequisitionSampleMenu"
        :dataToComponent="{
          isAddManualSamplesDisabled,
        }"
        position="normal"
        @option-click="onMenuItemClick" />
    </div>

    <!-- Dialog -->
    <Dialog
      :isShowing="showDialog"
      :useComponent="dialogComponent"
      :componentProps="dialogProps"
      :size="dialogSize"
      :easyClose="false"
      @close="showDialog = false" />
  </div>
</template>

<script>
import CloseIcon from '@/assets/svg/close.svg?inline'
import CheckIcon from '@/assets/svg/check.svg?inline'
import RequisitionSampleDetailModal from '@/components/Modals/RequisitionSampleDetailModal.vue'
import AddSamplesModal from '@/components/Modals/AddSamplesModal/AddSamplesModal.vue'
import Dialog from '@/components/Modals/Dialog.vue'
import { mapActions, mapGetters } from 'vuex'
import DotMenu from '../DotMenu/DotMenu.vue'
import RequisitionSampleMenu from './RequisitionSampleMenu.vue'

export default {
  name: 'RequisitionTableRowSample',
  props: {
    sample: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      RequisitionSampleMenu,

      // Checkbox handling
      allAnalysisSelected: false,
      sampleChecked: false,

      // Dialog
      showDialog: false,
      dialogComponent: {},
      dialogProps: {
        // AddSampleModal
        showAllSampleTypes: false,
        type: null,
        kindOfSample: '',
        // DetailsModal
        material: null,
        sample: null,
      },
      dialogSize: '',
    }
  },
  computed: {
    ...mapGetters([
      'screeningTypes',
      'requisitionUserSelections',
    ]),
    analyses: {
      get() {
        const sampleObject = this.requisitionUserSelections.find(
          (x) => x.sampleID === this.sample.id,
        )

        return sampleObject ? sampleObject.analysesIDs : []
      },
      // Forcing read-only mode
      set() {},
    },
    type() {
      const { rawSample } = this.sample
      return this.screeningTypes.find((type) => type.id === rawSample.typeID)
    },
    selectableAnalyses() {
      return this.sample.analyses.reduce((prev, analysis) => {
        if (analysis.missingResult) {
          prev.push(analysis.sampleTypeID)
        }

        return prev
      }, [])
    },
    isAddManualSamplesDisabled() {
      const { rawSample } = this.sample
      if (rawSample.kindOfSample === 'material' && this.type.type.customTypeName) {
        return true
      }

      return rawSample.status.includes('skip-') || rawSample.isAutoAssessment
    },
  },
  watch: {
    analyses: {
      handler() {
        this.updateComponentStates()
      },
      immediate: true,
    },
    selectableAnalyses: {
      async handler(value, old) {
        // If there have been no change to the available selectableAnalyses - terminate watch flow
        if (value.length === old.length) {
          if (value.every((id) => old.includes(id))) {
            return
          }
        }

        // Get list of removed and/or added analyses
        const removedIDs = old.filter((id) => !value.includes(id))
        const addedIDs = value.filter((id) => !old.includes(id))

        // Current selection without without removed analysis IDs
        const retainedIDList = this.analyses.filter((id) => !removedIDs.includes(id))

        // Update analyses selection
        this.updateSample({
          sampleID: this.sample.id,
          analysesIDs: [
            ...retainedIDList,
            ...addedIDs,
          ],
        })

        this.updateComponentStates()
      },
      deep: true,
    },
  },
  methods: {
    ...mapActions([
      'updateSample',
    ]),
    onCheckboxClick(e) {
      const newAnalysesList = e.target.checked
        ? [...this.analyses, e.target.value]
        : this.analyses.filter((a) => a !== e.target.value)

      this.updateSample({ sampleID: this.sample.id, analysesIDs: newAnalysesList })
    },
    onModifiedSelection() {
      this.$emit('modified-select', this.sample.rawSample.sampleNumber)
      this.toggleAllAnalyses(!this.sampleChecked)
    },
    onMenuItemClick(selected) {
      const { rawSample } = this.sample
      // Add / remove analyses
      if (selected === 'add-samples') {
        this.dialogProps.type = this.type
        this.dialogProps.kindOfSample = rawSample.kindOfSample
        this.dialogComponent = AddSamplesModal
        this.dialogSize = 'small'
        this.showDialog = true

        return
      }

      if (selected === 'details') {
        this.dialogComponent = RequisitionSampleDetailModal
        this.dialogSize = 'large'
        this.dialogProps.sample = this.sample.rawSample
        this.dialogProps.material = this.type
        this.showDialog = true

        return
      }
    },
    toggleAllAnalyses(setAllAsSelected) {
      this.$emit('selected', {
        sampleNumber: this.sample.rawSample.sampleNumber,
        isSelected: setAllAsSelected,
      })

      this.sampleChecked = setAllAsSelected
      this.updateSample({
        sampleID: this.sample.id,
        analysesIDs: setAllAsSelected ? [...this.selectableAnalyses] : [],
      })
    },
    updateComponentStates() {
      this.sampleChecked = this.analyses.length > 0
      this.allAnalysisSelected = this.analyses.length === this.selectableAnalyses.length
    },
  },
  components: {
    CheckIcon, DotMenu, Dialog, CloseIcon,
  },

}
</script>

<style lang="stylus" scoped>
  .RequisitionTableRowSample
    display block

  .RowGrid
    > div
      display flex
      align-items center
      justify-content center
      &[data-analysis], &:first-of-type
        padding 0
    .Title
      display flex
      flex-direction column
      text-align left
      align-items flex-start
      padding 0 5px
      span
        truncated()
        max-width 100%
        min-width 200px
      span:nth-of-type(2)
        font-size $font_size_xs
        margin-top -3px
    .IsCompletedSVG
      box(15px)

  .MenuButtonWrap
    display flex
    align-items center
    justify-content center
    box(40px)
    > *
      max-height 100%

  .RequisitionCheckbox
    box(40px)
    padding 5px
    overflow hidden
    &.PartialSelection svg
      fill lighten($color_grey_light, 30%)

  /** Checkbox CSS to be transfered to componen */
  .RequisitionCheckbox
    position relative

  .RequisitionCheckboxWrap
    display flex
    align-items center
    justify-content center
    box(100%)
    background-color #fff
    border 1px solid $color_grey_light
    input
      position absolute
      top 50%
      left 50%
      transform translate(-50%, -50%)
      box(100%)
      min-width 40px
      min-height 40px
      cursor pointer
    svg
      display block
      box(12px)
      pointer-events none
      opacity 0
      fill $color_grey_dark
    input:checked + svg
      opacity 1
</style>
