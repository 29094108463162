<template>
  <form
    class="RequisitionOptions"
    @submit.prevent="onSubmit">
    <div class="OptionList">
      <div class="Option">
        <Select
          v-if="validLabOptions.length > 1"
          name="laboratoryID"
          :label="mixWB('LABORATORY')"
          :selectedID="formData.laboratoryID"
          :options="validLabOptions"
          backgroundColor="white"
          @change="onFormFieldUpdate" />

        <InputField
          v-else
          name="laboratoryID"
          :label="mixWB('LABORATORY')"
          backgroundColor="white"
          :value="mixWB(selectedLaboratory.translation)"
          disabled />
      </div>

      <div class="Option">
        <InputField
          name="customOfferNumber"
          :label="mixWB('OFFER_NUMBER')"
          backgroundColor="white"
          :value="
            milvaSelected
              ? milvaLabDetails.euroFinsNumber
              : formData.customOfferNumber
          "
          :placeholder="defaultLaboratoryOfferNumber"
          :trimInput="true"
          :disabled="milvaSelected"
          :isRequired="
            milvaSelected
              ? false
              : !defaultLaboratoryOfferNumber
          "
          @on-update="onFormFieldUpdate" />
      </div>

      <div class="Option">
        <Select
          name="deliveryOptionID"
          :label="mixWB('DELIVERY_TIME_FOR_ANALYSES')"
          :selectedID="formData.deliveryOptionID"
          :options="analysisDeliveryTimeOptions"
          backgroundColor="white"
          @change="onFormFieldUpdate" />
      </div>

      <div class="Option">
        <InputField
          name="eanNumber"
          :label="mixWB('EAN_NUMBER')"
          backgroundColor="white"
          :value="
            milvaSelected
              ? ''
              : formData.eanNumber
          "
          :disabled="milvaSelected"
          @on-update="onFormFieldUpdate" />
      </div>

      <div class="Option Grow">
        <InputField
          name="extraEmails"
          :label="mixWB('EXTRA_EMAILS') + ' (' + mixWB('EXTRA_EMAILS_HEADER_INFO_TEXT') + ')'"
          backgroundColor="white"
          :value="formData.extraEmails"
          @on-update="onFormFieldUpdate" />
      </div>
    </div>

    <div class="ButtonsWrap">
      <span
        class="ExtraText"
        v-if="selectedDeliveryTimeOption.days === 1">
        {{ mixWB('1_DAY_DELIVERY_OBS_TEXT', [
          mixWB(selectedLaboratory.translation),
          mixFormatPhoneNumber(selectedLaboratory.phone),
        ]) }}
      </span>
      <Button
        v-show="anyChangesDetected && !isSaving"
        :text="mixWB('CANCEL')"
        :fullWidth="false"
        color="grey"
        :outlined="true"
        @button-click="onResetClick" />
      <Button
        type="submit"
        :text="mixWB('SAVE')"
        :fullWidth="false"
        :isDisabled="!anyChangesDetected"
        :isLoading="isSaving" />
    </div>
  </form>
</template>

<script>
import Select from '@/components/FormElements/Select.vue'
import InputField from '@/components/FormElements/InputField.vue'
import { mapGetters, mapActions } from 'vuex'
import Button from '@/components/Buttons/Button.vue'
import milvaLabDetails from '@/globals/json/milva-lab-details.json'

export default {
  name: 'RequisitionOptions',
  data() {
    return {
      isSaving: false,
      originalFormDataString: '',
      milvaLabDetails,
      formData: {
        laboratoryID: '',
        deliveryOptionID: '',
        customOfferNumber: '',
        eanNumber: '',
        extraEmails: '',
      },
    }
  },
  computed: {
    ...mapGetters([
      'currentAccount',
      'currentScreeningData',
      'activeLaboratoriesAsArray',
      'analysisDeliveryTimeOptionsAsArray',
    ]),
    milvaSelected() {
      return this.selectedLaboratory?.id === 'milvaEuroFins' || false
    },
    anyChangesDetected() {
      const newDataString = JSON.stringify(this.formData)
      return newDataString !== this.originalFormDataString
    },
    validLabOptions() {
      return this.activeLaboratoriesAsArray.reduce((prev, lab) => {
        prev.push({
          id: lab.id,
          text: this.mixWB(lab.translation),
        })
        return prev
      }, [])
    },
    selectedLaboratory() {
      return this.activeLaboratoriesAsArray.find((x) => x.id === this.formData.laboratoryID)
    },
    analysisDeliveryTimeOptions() {
      return this.analysisDeliveryTimeOptionsAsArray.map((item) => ({
        id: item.id,
        days: item.days,
        text: item.days === 1
          ? this.mixWB('1_DAY_X_O_CLOCK', [item.hours.toString()])
          : this.mixWB('X_DAYS_X_O_CLOCK', [item.days.toString(), item.hours.toString()]),
      }))
    },
    selectedDeliveryTimeOption() {
      return this.analysisDeliveryTimeOptionsAsArray.find(
        (x) => x.id === this.formData.deliveryOptionID,
      )
    },
    defaultLaboratoryOfferNumber() {
      const id = this.selectedLaboratory?.id

      if (id === 'euroFins') {
        return this.currentAccount.euroFinsNumber
      }

      if (id === 'hojvang') {
        return this.currentAccount.hojvangNumber
      }

      return ''
    },
  },
  watch: {
    anyChangesDetected: {
      handler(bool) {
        this.$emit('changes-status-update', bool)
      },
      immediate: true,
    },
    selectedLaboratory: {
      handler(lab) {
        if (lab) {
          this.setLaboratory(lab)
        }
      },
      immediate: true,
    },
    'formData.deliveryOptionID': {
      handler(id) {
        if (id) {
          const selectedDelivery = this.analysisDeliveryTimeOptions.find((a) => a.id === id)

          this.setDeliveryTime(selectedDelivery)
        }
      },
      immediate: true,
    },
  },
  methods: {
    ...mapActions([
      'setDeliveryTime',
      'setLaboratory',
    ]),
    setDataOnLoad() {
      const { requisition } = this.currentScreeningData

      // Set data
      this.formData.laboratoryID = requisition.laboratoryID
      this.formData.deliveryOptionID = requisition.deliveryOptionID
      this.formData.customOfferNumber = requisition.customOfferNumber
      this.formData.eanNumber = requisition.eanNumber
      this.formData.extraEmails = requisition.extraEmails

      // Set originalFormDataString in order to detect changes
      this.originalFormDataString = JSON.stringify(this.formData)
    },
    onFormFieldUpdate({ name, value }) {
      this.formData[name] = value
    },
    onResetClick() {
      this.setDataOnLoad()
    },
    async onSubmit() {
      if (this.isSaving) {
        return
      }

      this.isSaving = true

      const formDataCopy = { ...this.formData }

      await this.$store.dispatch('asyncUpdateScreening', { requisition: formDataCopy })

      setTimeout(() => {
        this.isSaving = false
        this.setDataOnLoad()
      }, 250)
    },
  },
  components: { Select, InputField, Button },
  created() {
    this.setDataOnLoad()
  },
}
</script>

<style lang="stylus" scoped>
  .RequisitionOptions
    position relative
    min-height 100px
    page-wrap-outer-1()
    background-color #fff
    box-shadow $box_shadow_1

  .OptionList
    display grid
    grid-template-columns 1fr
    column-gap: 20px
    row-gap: 10px
    +above(600px)
      grid-template-columns repeat(2, 1fr)
    +above($desktop)
      grid-template-columns repeat(3, 1fr)

    .Option
      &.Grow
        +above(600px)
          grid-column span 2
      span
        display block

  .ExtraText
    display flex
    align-items center
    background-color $color_yellow_lightest
    padding 10px 20px
    font-size 0.875rem
    font-weight bold

  .ButtonsWrap
    border-top 1px solid $color_grey_lighter
    margin-top 5px
    padding-top: 15px
    display flex
    justify-content flex-end
    align-items flex-start
    +below($tablet)
      flex-wrap wrap
    .ButtonWrap
      margin-left 10px
    .ExtraText
      max-width 510px
      min-height 40px
      margin-right auto
      +below($tablet)
        max-width 100%
        margin-bottom 20px
</style>
