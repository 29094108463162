<template>
  <div class="Select">
    <label
      v-if="label"
      :for="name">{{ label }}</label>
    <div
      class="SelectWrap"
      :class="rootClasses">
      <select
        :id="name"
        :name="name"
        @change="onOptionChange">
        <!-- Groups -->
        <template v-if="optionGroups.length">
          <optgroup
            :label="group.title"
            v-for="group in optionGroups"
            :key="group.title">
            <option
              v-for="item in group.options"
              :key="item.id"
              :selected="item.id === selectedID"
              :value="item.id">{{ item.text }}</option>
          </optgroup>
        </template>

        <!-- No groups -->
        <template v-else>
          <option
            v-for="item in options"
            :key="item.id"
            :selected="item.id === selectedID"
            :value="item.id">{{ item.text }}</option>
        </template>
      </select>

      <div class="ArrowWrap">
        <AngleRightIcon />
      </div>
    </div>
  </div>
</template>

<script>
import AngleRightIcon from '@/assets/svg/angle-right.svg?inline'

export default {
  name: 'Select',
  props: {
    name: {
      type: String,
      required: true,
    },
    selectedID: {
      type: String,
      required: true,
    },
    optionGroups: {
      type: Array,
      required: false,
      default: () => [],
    },
    options: {
      type: Array,
      required: false,
      default: () => [],
    },
    label: {
      type: String,
      required: false,
    },
    backgroundColor: {
      type: String,
      required: false,
      default: 'grey', // 'white' | 'grey'
    },
    hasShadow: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  computed: {
    rootClasses() {
      return {
        HasShadow: this.hasShadow,
        BackgroundColorGrey: this.backgroundColor === 'grey',
        BackgroundColorWhite: this.backgroundColor === 'white',
      }
    },
  },
  methods: {
    onOptionChange({ target }) {
      const optionID = target.options[target.selectedIndex].value
      this.$emit('change', { name: this.name, value: optionID })
    },
  },
  components: {
    AngleRightIcon,
  },
}
</script>

<style lang="stylus" scoped>
  .Select
    position relative
    margin-bottom 10px
    label
      padding-bottom 5px
      display block

  .SelectWrap
    position relative
    &.HasShadow
      box-shadow $box_shadow_1
    &.BackgroundColorGrey
      select
        background-color $color_grey_lightest
    &.BackgroundColorWhite
      select
        background-color #fff
    select
      display block
      height 40px
      padding-left 11px
      font-size 1.125rem
      width 100%
      border 1px solid $color_grey_lighter
      appearance none
      border-radius 0
      outline none
    optgroup
      text-transform uppercase
      font-size 0.875rem
    option
      font-size 0.875rem

  .ArrowWrap
    box(16px)
    position absolute
    top 14px
    right 14px
    transform rotateZ(90deg)
    pointer-events none
    svg
      fill $color_grey
</style>
