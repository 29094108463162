<template>
  <div class="RequisitionSampleRangeSelection">
    <label
      v-if="label"
      :for="name">{{ label }}</label>
    <span class="ErrorText">{{ errorText }}</span>
    <div class="InputContainer">
      <input
        v-model="textRange"
        type="text"
        :name="name"
        :placeholder="placeholder"
        @keypress.space.prevent
        @keypress.enter="setSelectionRange()"/>
      <Button
        :text="mixWB('SELECT')"
        :fullWidth="false"
        :isLoading="requisitionTableIsUpdating"
        @button-click="setSelectionRange()" />
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import Button from '../Buttons/Button.vue'

export default {
  name: 'RequisitionSampleRangeSelection',
  props: {
    name: {
      type: String,
      required: true,
    },
    label: {
      type: String,
      default: '',
    },
    placeholder: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      textRange: '',
      numericRange: [],
      errorText: '',
    }
  },
  computed: {
    ...mapGetters([
      'requisitionTableIsUpdating',
    ]),
  },
  watch: {
    textRange(value, old) {
      // Look for any char that's not a number, comma or hyphen
      const anyDisallowedRegEx = /[^\d|,|-]/

      const allCharsOK = !anyDisallowedRegEx.test(value)

      // If new input contains disallowed chars - use the old value
      if (!allCharsOK) {
        this.textRange = old
        return
      }

      // Use new value
      this.textRange = value
      this.errorText = ''
    },
  },
  methods: {
    setSelectionRange() {
      const selectedSamples = this.textRange.split(',').reduce((prev, selection) => {
        if (!selection.includes('-')) {
          prev.push(selection * 1)
          return prev
        }

        const [from, to] = selection.split('-')

        if (to.length === 0) {
          this.errorText = this.mixWB('INVALID_VALUE_IN', [selection])
          return prev
        }

        const start = from * 1
        const end = to * 1

        if (start > end) {
          this.errorText = this.mixWB('INVALID_VALUE_IN', [selection])
        }

        // Fill selected range
        const completeRange = []
        const rangeSize = end - start

        for (let index = 0; index <= rangeSize; index += 1) {
          completeRange.push(start + index)
        }

        prev.push(...completeRange)

        return prev
      }, [])

      // Emit compiled result to parent
      this.$emit('update', selectedSamples)
    },
  },
  components: { Button },

}
</script>

<style lang="stylus" scoped>
  .RequisitionSampleRangeSelection
    display flex
    flex-direction column
    align-items stretch
    label, .ErrorText
      & ~ .InputContainer
        margin-top 2px

  .InputContainer
    display flex
    align-items center
    input
      min-height 40px
      padding 0 10px
      border 1px solid $color_grey_lighter
    >>> .ButtonWrap
      margin-bottom 0

  .ErrorText
    font-size 0.875rem
    color $color_report_red
</style>
