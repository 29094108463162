<template>
  <div class="RequisitionTableSamples">
    <div
      class="RequisitionCompleted"
      v-if="allAnalysesCompleted">
      {{ mixWB('REQUISITION_ALL_SAMPLES_HAS_RESULTS_TEXT') }}
    </div>

    <div
      class="RequisitionActions"
      v-if="!allAnalysesCompleted">
      <Button
        :text="mixWB('SELECT_ALL_ANALYSES')"
        :fullWidth="false"
        :isLoading="requisitionTableIsUpdating"
        @button-click="selectAllAnalyses(true)" />

      <Button
        :text="mixWB('DESELECT_ALL_ANALYSES')"
        :fullWidth="false"
        :isLoading="requisitionTableIsUpdating"
        @button-click="selectAllAnalyses(false)" />

      <RequisitionSampleRangeSelection
        :name="'rangeSelection'"
        :label="mixWB('SELECT_SPECIFIC_SAMPLES')"
        :placeholder="'1,3,5,10-75,67'"
        @update="onRangeSelection"/>

      <ToggleButton
        groupName="show-completed-samples"
        value="show-completed-samples"
        :isSelected="showCompletedSamples"
        :text="mixWB('SHOW_COMPLETED_SAMPLES')"
        @check="onToggleShowCompletedSamples" />

      <hr />
    </div>

    <div class="RequisitionTable js-scroll-container">
      <!-- Head -->
      <RequisitionTableRowHead
        class="TableRow Head"
        :analysesData="analysesData" />
      <!-- Samplelines -->
      <RequisitionTableRowSample
        v-for="sample in samplesList"
        :key="sample.id"
        class="TableRow Sample"
        ref="SampleRows"
        :sample="sample"
        @modified-select="onModifiedSelect"
        @selected="onSampleSelection" />
    </div>
  </div>
</template>

<script>
import { orderBy } from 'lodash-es'
import { mapActions, mapGetters } from 'vuex'
import RequisitionTableRowHead from './RequisitionTableRowHead.vue'
import RequisitionTableRowSample from './RequisitionTableRowSample.vue'
import Button from '../Buttons/Button.vue'
import RequisitionSampleRangeSelection from './RequisitionSampleRangeSelection.vue'
import ToggleButton from '../FormElements/ToggleButton.vue'

export default {
  name: 'RequisitionTableSamples',
  data() {
    return {
      showCompletedSamples: false,
      lastSelectedSampleNumber: 0,
    }
  },
  computed: {
    ...mapGetters([
      'sampleTypesAsArray',
      'activeLaboratoriesAsArray',
      'screeningSamples',
      'screeningTypes',
      'standardSamplesStatus',
      'currentScreeningData',
      'isRequisitionPreviewAvailable',
      'requisitionSampleList',
      'requisitionTableIsUpdating',
    ]),
    allAnalysesCompleted() {
      return this.standardSamplesStatus.isCompleted
    },
    selectedLaboratory() {
      return this.activeLaboratoriesAsArray.find(
        (x) => x.id === this.currentScreeningData.requisition.laboratoryID,
      )
    },
    analysesData() {
      return this.sampleTypesAsArray.reduce((prev, sampleType) => {
        const data = {
          id: sampleType.id,
          title: '',
          code: '',
        }

        prev.push(data)

        if (!this.selectedLaboratory) {
          return prev
        }

        const labRequisitionItem = this.selectedLaboratory.requisition.find(
          (x) => x.sampleTypeID === sampleType.id,
        )

        data.title = {
          selected: this.mixWB(sampleType.translation),
          base: this.mixWBBase(sampleType.translation),
        }
        data.code = labRequisitionItem.code || ''
        return prev
      }, [])
    },
    samplesList() {
      if (this.allAnalysesCompleted) {
        return this.samples
      }

      return this.showCompletedSamples ? this.samples : this.samples.filter((x) => !x.isCompleted)
    },
    samples() {
      const samples = []
      const displayedAnalysesTypeIDs = this.analysesData.map((x) => x.id)

      this.screeningSamples.forEach((sample) => {
        if (!sample.sampleNumber) {
          return
        }

        const type = this.screeningTypes.find((x) => x.id === sample.typeID)

        if (!type) {
          return
        }

        // Find the right sample status object
        const sampleStatus = this.standardSamplesStatus.list.find(
          (item) => item.sampleNumber === sample.sampleNumber,
        )

        if (!sampleStatus) {
          return
        }

        let hasMissingAnalysisResult = false

        // Take only analysis we want to display
        const analyses = displayedAnalysesTypeIDs.map((id) => {
          const analysis = sampleStatus.analyses.find((item) => item.sampleTypeID === id)

          const item = {
            isCompleted: analysis.status.complete,
            unintentional: analysis.status.unintentional,
            missingResult: analysis.status.missing,
            sampleTypeID: analysis.sampleTypeID,
            groupID: analysis.groupID,
          }

          // Track if any missing result in each analysis
          if (analysis.status.missing) {
            hasMissingAnalysisResult = true
          }

          return item
        })

        const item = {
          id: sample.id,
          displayID: `P${ sample.sampleNumber }`,
          title: {
            selected: type.getTitle({ category: true, details: true, useBaseTranslation: false }),
            base: type.getTitle({ category: true, details: true, useBaseTranslation: true }),
          },
          isCompleted: sampleStatus.isCompleted || !hasMissingAnalysisResult,
          analyses: [...analyses],
          rawSample: sample,
        }

        samples.push(item)
      })

      const hasUncompletedSamples = !samples.some((s) => !s.isCompleted)
      this.setRequisitionCompleted(hasUncompletedSamples)

      return orderBy(samples, 'rawSample.sampleNumber', 'asc')
    },
  },
  methods: {
    ...mapActions([
      'addSelectableSamples',
      'toogleRequisitionSamples',
      'setRequisitionCompleted',
    ]),
    onSampleSelection({ sampleNumber, isSelected }) {
      if (!isSelected) {
        return
      }

      this.lastSelectedSampleNumber = sampleNumber
    },
    onModifiedSelect(sampleNumber) {
      // Find smallest and largest value
      const start = sampleNumber > this.lastSelectedSampleNumber
        ? this.lastSelectedSampleNumber
        : sampleNumber
      const end = sampleNumber > this.lastSelectedSampleNumber
        ? sampleNumber
        : this.lastSelectedSampleNumber

      // Find sample numbers missing in range
      const samplesMissingSelection = []
      const rangeSize = end - start

      for (let index = 1; index < rangeSize; index += 1) {
        samplesMissingSelection.push(start + index)
      }

      // Set missing sample selection as selected
      const targetSamples = this.samples.filter(
        (sample) => samplesMissingSelection.includes(sample.rawSample.sampleNumber),
      )

      this.toogleRequisitionSamples({ samples: targetSamples, bool: true })

      // Update latest selected sample
      this.lastSelectedSampleNumber = sampleNumber
    },
    selectAllAnalyses(bool) {
      // Toggle all sample rows
      this.toogleRequisitionSamples({ samples: this.samples, bool })
    },
    onRangeSelection(selectedSamples) {
      // Set missing sample selection as selected
      const targetSamples = this.samples.filter(
        (sample) => selectedSamples.includes(sample.rawSample.sampleNumber),
      )

      this.toogleRequisitionSamples({ samples: targetSamples, bool: true })
    },
    onToggleShowCompletedSamples() {
      this.showCompletedSamples = !this.showCompletedSamples
    },
  },
  components: {
    RequisitionTableRowSample,
    RequisitionTableRowHead,
    Button,
    RequisitionSampleRangeSelection,
    ToggleButton,
  },
  created() {
    if (!this.requisitionSampleList.length) {
      this.addSelectableSamples(this.samples)
    }
  },
}
</script>

<style lang="stylus" scoped>
  .RequisitionTableSamples
    display block

    .RequisitionCompleted + *, .RequisitionActions + *
      margin-top 40px

  .RequisitionCompleted
    background-color $color_yellow_lightest
    padding 10px 20px
    text-align center
    font-weight bold

  .RequisitionActions
    display flex
    align-items flex-end
    flex-wrap wrap
    margin 0 -10px -20px
    > * {
      margin 0 10px 20px
    }
    .ToggleButton
      min-height 40px
      white-space nowrap
    hr
      width 100%
      background-color $color_grey_lighter
      height 1px
      border none

  // Requisition table
  .RequisitionTable
    overflow auto
    width 100%

    .TableRow
      display grid
      grid-template-columns auto 41px

    >>> .RowGrid
      display grid
      grid-template-columns repeat(2, 41px) minmax(220px, auto) repeat(8, 41px)

    // Sticky setup
    .Head
      position sticky
      top 0
      z-index 10

    .Head, .Sample
      >>> .RowGrid, >>> .RowGrid + *
          background-color #fff

      >>> .RowGrid > :nth-child(1)
          position sticky
          left 0
          z-index 5
          background-color #fff

      >>> .RowGrid > :nth-child(2)
          position sticky
          left 41px
          z-index 5
          background-color #fff

    // Color setup for analyses columns
    >>> .RowGrid
      [data-analysis="ST-1"]
        background-color rgba($color_yellow_lightest, 0.65)
      [data-analysis="ST-2"]
        background-color rgba($color_salmon_lightest, 0.65)
      [data-analysis="ST-3"]
        background-color rgba($color_primary_lightest, 0.65)
      [data-analysis="ST-4"]
        background-color rgba($color_purple_lightest, 0.65)
      [data-analysis="ST-5"]
        background-color rgba(#fff, 1)
      [data-analysis="ST-6"]
        background-color rgba($color_info_bg, 0.65)
      [data-analysis="ST-7"]
        background-color rgba($color_yellow_lightest, 1)
      [data-analysis="ST-8"]
        background-color rgba($color_salmon_lightest, 1)

    // Column title text styling
    .Head
      >>> .RowGrid > div
        display flex
        flex-direction column-reverse
        align-items flex-start
        justify-content center
        transform rotate(180deg)
        padding 10px 5px
        writing-mode vertical-lr
        white-space pre-wrap
        span:first-child
          font-size: $font_size_s
        span:nth-child(2)
          padding-top 5px
          font-size: $font_size_xxs

    // Border styling
    >>> .RowGrid
      border-bottom 1px solid $color_grey_lighter

    .Head >>> .RowGrid > div:nth-of-type(n+3)
      // The divs have been rotated 180deg - thus left is on the right side
      border-left 1px solid $color_grey_lighter

    .Head >>> .RowGrid
      border-left 1px solid transparent

    .Sample >>> .RowGrid
      border-left 1px solid $color_grey_lighter

    .Sample >>> .RowGrid > div
      border-right 1px solid $color_grey_lighter
</style>
